import React, { useEffect } from "react";
import "./InspectionServices.scss";

export default function InspectionServices() {
  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    })
}, [])
  return (
    <div className="inspection-services-all-content-alignment-for-page">
      <div className="container">
        <div className="white-box">
          <div className="inspection-services-heding-alignment">
            <h4>Inspection Services</h4>
          </div>

          <div className="inspection-services-side-details-alignment">
            <div className="inspection-services-sideItem">
              <div className="inspection-services-list-alignemnt">
                <ul>
                  <li>Product Testing Services</li>
                  <li>Pre-Production Inspections</li>
                  <li>First Batch Inspection</li>
                  <li>During Production Inspection</li>
                  <li>Pre-Shipment Inspection</li>
                  <li>Container Loading Inspection</li>
                  <li>On-site Sorting Service</li>
                  <li>Supplier Verification</li>
                  <li>Step-by-Step Order Tracking</li>
                  <li>Surprise Factory Visit</li>
                  <li>Price and Terms Negotiations</li>
                  <li>Product Sourcing</li>
                  <li>Quality Assurance</li>
                  <li>Engineering</li>
                  <li>Lab Testing Advise</li>
                </ul>
              </div>
            </div>
            <div className="inspection-services-sideItem">
              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>Product Testing Services</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    Product testing services is very necessary for all import and export companies. As more jurisdictions adopt legislation making it
                    necessary for companies to declare their products' material and chemical make-up, and restricting the use of hazardous substances,
                    manufacturers and importers need to ensure that their products, parts and components comply in order to maintain market access.{" "}
                  </p>

                  <p>
                    SeaRates product testing services can be tailored to complement compliance strategies for numerous health and environmental
                    regulations including: REACH, CPSIA , RoHS, California Proposition 65, and many other global legislations and requirements.
                    SeaRates develop and execute product testing plans to determine a product's compliance status with regards to material/chemical
                    make-up; to determine which elements need to be replaced or redesigned; and to determine how new compliant materials and
                    components will perform in the field: providing not only environmental compliance but also product reliability.
                  </p>
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>Pre-Production Inspections</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    {" "}
                    Our Pre-Production Inspection service is your key to due diligence on a factory under consideration for partnership. Searates PPI
                    allows our customers to have a mature view of materials preparation, tooling preparation, machinery available, labeling,
                    production scheduling and all arrangements for production and packaging.
                  </p>

                  <p>
                    The carpenter's rule is to measure twice, cut once. Pre-Production Inspection allows for corrections and improvements before mass
                    production begins. SeaRates can systematically break down the situation against your specifications including checks on:
                  </p>
                  <ul>
                    <li>- components</li>
                    <li>- materials</li>
                    <li>- finished product</li>
                    <li>- quantity and quality of raw materials</li>
                    <li>- any potential dangers in production with which to prevent costly changes</li>
                    <li>- suggest any lab testing on equipment/components deemed by our experienced inspectors</li>
                  </ul>
                  <p>
                    SeaRates understands what our overseas customers need and our inspectors and staff are trained to keep one thing in mind – our
                    customers' needs. SeaRates continues to add value to orders for our customers by staving off potential disaster by properly
                    evaluating the factory and production condition from an outside persepctive.
                  </p>
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>First Batch Inspection</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    {" "}
                    The purpose of a First Batch Inspection, or Intial Quality Inspection, is to check production procedures and quality of the “first
                    batch” of product. By correcting mistakes in the beginning stages of production, SeaRates can consult both our customer and their
                    factory on how to proceed in the most efficient and effective manner to save the order.
                  </p>
                  <p>SeaRates generally implements the following method for First Batch Inspection:</p>
                  <ul>
                    <li>1. Check carefully materials and components for quality and consistency</li>
                    <li>2. Closely check batch compared to approved sample and customer specifications and requirements</li>
                    <li>3. Communicate and confirm the factory is clear about possible changes that need to be made in the production process</li>
                    <li>4. Monitor factory in-house QC policy and management</li>
                  </ul>
                  <p className="top-padding">
                    {" "}
                    SeaRates takes a unique and refreshing approach to our inspection service. We are committed to offering our customers a
                    comprehensive service, “A to Z” if you will, whereby you will never be stranded on a failed inspection. The main difference
                    between us and the inspection-only companies out there is that we pride ourselves on the knowledge we have of ordering from
                    Chinese factories, and we use that knowledge and experience on your behalf to source, secure, inspect and deliver your products to
                    you. That being said the inspection we do for your company is one part of the entire process. Our inspection team is trained to
                    understand the complete process of the entire order, and therefore we support our customers before, during and after the order.
                    Our service team will get involved in your order and ensure that order scheduling, tracking, communications with you and the
                    factory – these are all key elements for a successful order. We are involved in your business and will work and fight on your
                    behalf to
                  </p>{" "}
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>During Production Inspection</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    {" "}
                    The purpose of a During Production Inspection is to check on production mid-way through the production process to check several
                    things including:
                  </p>

                  <ul>
                    <li>- Will the factory be on-time on total production?</li>
                    <li>- Does the production product meet requirements and spectifications listed in the Purchase Order?</li>
                    <li>- Are the proper in-house QC measures in place?</li>
                    <li>- How can I prove to my customer that production is going smoothly</li>
                  </ul>
                  <p className="top-padding">
                    Although the above things are important, perhaps the most important purpose for conducting a During Production Inspection is to
                    show the factory that you are serious and will be checking on this order throughout the process and when it comes to quality and
                    time requirements, you mean business! Let SeaRates help manage your order and your factory.
                  </p>

                  <p>Our During Production Inspection includes:</p>
                  <ul>
                    <li>1. Product appearance</li>
                    <li>2. Weight measurements</li>
                    <li>3. Dimensions measurements</li>
                    <li>4. Labeling and bar codes</li>
                    <li>5. Workmanship</li>
                    <li>6. Quantities of product completed</li>
                    <li>7. Packaging and packing including shipping marks</li>
                  </ul>

                  <p className="top-padding">
                    We also pay careful attention to any additional customized requirements you might need highlighted in the PO or communicated to us
                    through our Order Management System. SeaRates takes a unique and refreshing approach to our inspection service. We are committed
                    to offering our customers a comprehensive service, “A to Z” if you will, whereby you will never be stranded on a failed
                    inspection. The main difference between us and the inspection-only companies out there is that we pride ourselves on the knowledge
                    we have of ordering from Chinese factories, and we use that knowledge and experience on your behalf to source, secure, inspect and
                    deliver your products to you. That being said the inspection we do for your company is one part of the entire process. Our
                    inspection team is trained to understand the complete process of the entire order, and therefore we support our customers before,
                    during and after the order. Our service team will get involved in your order and ensure that order scheduling, tracking,
                    communications with you and the factory – these are all key elements for a successful order. We are involved in your business and
                    will work and fight on your behalf to ensure mistakes are reduced to as few as possible.
                  </p>
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>Pre-Shipment Inspection</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    The order is complete (or close to complete). Don't you want to know what you'll expect to find when you or your customer can
                    expect to find when the container is opened? The purpose of the Pre-Shipment Inspection is to give you a clear depiction of the
                    state of your order before it is shlpped. There is nothing worse for your business than receiving product that you or your
                    customer cannot accept – thus affecting your bottom line and more importantly your reputation!!{" "}
                  </p>

                  <p>SeaRates systematically and thoroughly follows your guidelines to inspect your order including the following aspects:</p>
                  <ul>
                    <li>1. Product appearance</li>
                    <li>2. Weight measurements</li>
                    <li>3. Dimensions measurements</li>
                    <li>4. Labeling and bar codes</li>
                    <li>5. Workmanship</li>
                    <li>6. Quantities of product completed</li>
                    <li>7. Packaging and packing including shipping marks</li>
                  </ul>

                  <p className="top-padding">
                    We also provide a professional SeaRates Inspection Report in PDF format for your review. You can also check all information on our
                    SeaRates Order Processing System SeaRates takes a unique and refreshing approach to our inspection service. We are committed to
                    offering our customers a comprehensive service, “A to Z” if you will, whereby you will never be stranded on a failed inspection.
                    The main difference between us and the inspection-only companies out there is that we pride ourselves on the knowledge we have of
                    ordering from Chinese factories, and we use that knowledge and experience on your behalf to source, secure, inspect and deliver
                    your products to you. That being said the inspection we do for your company is one part of the entire process. Our inspection team
                    is trained to understand the complete process of the entire order, and therefore we support our customers before, during and after
                    the order. Our service team will get involved in your order and ensure that order scheduling, tracking, communications with you
                    and the factory – these are all key elements for a successful order. We are involved in your business and will work and fight on
                    your behalf to ensure mistakes are reduced to as few as possible.
                  </p>
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>Container Loading Inspection</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    {" "}
                    The purpose of Container Loading Inspection is to minimize surprises. Our trained inspectors can supervise the loading and ensure
                    that you know what to expect when the container is delivered to you or directly to your customer.
                  </p>
                  <ul>
                    <li>1. Full Quantity Check</li>
                    <li>2. Check of the Packing List compared to loading</li>
                    <li>3. Conditions of the container inside and out</li>
                    <li>4. Check of Quality of cartons</li>
                    <li>5. Quick check of product quality before shipment</li>
                  </ul>

                  <p className="top-padding">
                    Quality cartons packed into a clean container in a way that protects the goods during transit with shipping marks and quantities
                    matching your requirements. SeaRates gets the results you need to order with confidence!
                  </p>
                </div>
              </div>
              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>On-site Sorting Service</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    The purpose of On-site Sorting Service is simple: when your factory fails inspection and there is work to do to sort defective
                    products from the good ones, SeaRates can manage the process to keep your factory honest and ensure a problem order becomes a
                    successful one. Inspecting as many units as possible in the shortest time possible following a short list of specific points is
                    our goal here. According to your instruction, SeaRates onsite inspection expert(s) will inspect as many products as possible in a
                    short time frame to pull out defective goods and have the factory rework them, reproduce replacements, or refund you by reducing
                    the invoice balance on final payment. SeaRates is efficient. For OSS, we understand that time is of the essence and that you might
                    need to catch a vessel to meet you and/or your customer's deadline. We are the best in the business to getting your order back to
                    spec when needed. SeaRates has singlehandedly saved orders for our customers because we are willing to stay on factory site and do
                    the dirty work to right a wrong order. Need this service? Let us know!
                  </p>
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>Supplier Verification</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p> SEARATES is dedicated to demystify the identity of suppliers and potential suppliers. We'll get to the truth for you.</p>

                  <p>We do the following checks of supplier verification process:</p>
                  <ul>
                    <li>- Is the supplier a trading company or a factory?</li>
                    <li>1. Check the factory capacity and compare that to your ordering needs</li>
                    <li>2. Check the supplier's history on making the product you need – and their previous business experience in the past</li>
                    <li>3. Equipment check including machines and plant</li>
                    <li>4. Labor check including number of employees, engineers, managers, management structure</li>
                    <li>5. Certifications and documents confirmation</li>
                  </ul>

                  <p className="top-padding">Factory Audit</p>
                  <p>
                    When deciding on a supplier partner, you need to know who you're dealing with. SEARATES can do an on-site audit for you to ensure
                    that the factory you've chosen can meet your needs and requirements, and allows you to compare several suppliers to find the right
                    one to do the job.
                  </p>

                  <p>We do the following checks:</p>
                  <ul>
                    <li>1. Check the factory scale / aptitudes / operation & capacity</li>
                    <li>2. Development / technology</li>
                    <li>3. Quality management</li>
                    <li>4. Delivery time & follow-up management</li>
                    <li>5. State of ethical management</li>
                  </ul>
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>Step-by-Step Order Tracking</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    {" "}
                    Start from Order Scheduling, we trace Sample, Material, Molding & Tooling, Order Accessories, Production, Inspection, Shipment and
                    even Payment. When dealing with several orders at several different factories at the same time, it is not easy to stay abreast of
                    estimated production schedules. Compound that with the fact that factories are often not informative or open when it comes to
                    their own production schedules and orders might be delayed. SeaRates China has the capability to constantly check on the status of
                    your company's orders, accessible to you online 24 hours / day, 7 days / week so you have the knowledge you need to stay on top of
                    all orders.
                  </p>

                  <p>
                    SeaRates will contact your suppliers regularly and update your customer login account to inform you about the current situation
                    with your order. If the factory is behind or there is a delay for any reason, a notice will be sent to you. In this case, we
                    provide many action options to push the factory to be on time.
                  </p>

                  <ul>
                    <li>1. Order begins</li>
                    <li>2. We enter the order into our database with full projections according to PO and factory info</li>
                    <li>3. Contact supplier at least twice per week and update the information in the system</li>
                    <li>4. Update customer in Weekly Update by email</li>
                    <li>5. Plan inspection at least 1 week in advance (if valid)</li>
                  </ul>
                  <p className="top-padding">
                    Our order follow-up procedures are systematic and action-oriented. SeaRates China's online tracking system SeaRates In-Sync is an
                    integral platform to assist you in staying up to date on your orders. It is impossible to calculate how much we have saved our
                    customers over the years by alerting them in advance to problem areas in delivery times. We can do the same for your company.
                  </p>
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>Surprise Factory Visit</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    {" "}
                    During the production process you might want someone to make a surprise visit on your behalf to your factory. You might not need a
                    full inspection – just need someone to check in with the factory and confirm what the factory has reported. SeaRates offers the
                    economical Surprise Visit for a half-day service in coastal regions in China.
                  </p>

                  <p>We will arrive on site and report back to our customers a simple report including but not limited to the following:</p>
                  <ul>
                    <li>- what %% of production has occurred</li>
                    <li>- are we still on schedule with the order</li>
                    <li>- a quick check for quality</li>
                    <li>- is the packaging ready and correct?</li>
                  </ul>
                  <p className="top-padding">
                    We will have the report out to you the same day, and can check on the specific questions that you have. With our reach including
                    most of coastal China, we have the manpower it takes to make an Order Check possible – saving you money. Our customers like the
                    option of the Surprise Visit to avoid further problems in the future, and also save time and money. The result of the Surprise
                    Visit is not a full inspection, just having someone on your side going to the factory site to check the basics of your order. The
                    result is that you know the real situation and can deal with the factory accordingly.
                  </p>
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>Price and Terms Negotiations</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    Improving our customers' bottom line is a top priority for us and that means locating qualified suppliers, comparing prices and
                    using negotiation skills to get a fair. Say the word and SeaRates will go back to the negotiating table with your current
                    factories and their competitors to ensure that YOU are getting the best price possible for your product. We take several factors
                    into account when negotiating a fair price including materials, market prices, etc. Experience allows SeaRates to break products
                    down by components to calculate advantageous prices in a variety of product categories. We use this knowledge to negotiate better
                    deals with suppliers on your behalf. On average, SeaRates' price negotiation skills have created product price reductions of
                    2-10%% for each of our customers. We will get results for your company and increase your profit margin! Your success is our
                    success. .
                  </p>
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>Product Sourcing</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    SeaRates is dedicated to identifing, approaching, engaging and comparing quality suppliers for our customers to properly evaluate
                    which will be the best supplier partner for your business, your orders and your customers. SeaRates China has put orders in at
                    over 500 factories in China. SeaRates Sourcing Process ensures that we locate the right factories for the job at hand. With 15
                    years experience in sourcing and production, our database of suppliers is vast and we know how to size up new potential suppliers.
                    With our local presence in China, we help our customers to minimize the risk out of putting orders in at a new factory. Couple
                    this with our consistent pursuit of Customer Satisfaction, we efficiently and comprehensively accumulate information necessary to
                    make a buying decision clear. Based on factors such as passing quality inspections, on-time delivery, professionalism, financial
                    stability of factory - the successful rate of orders placed to the factories that we recommend to customers is off the charts! We
                    do the research, you get the results.
                  </p>
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>Quality Assurance</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    {" "}
                    Proper preparation and evaluation before an order can prevent big headaches down the road. Consider all the details of your order
                    before it is placed. We can assist you to evaluate your product and the raw materials that will be needed and also areas of
                    caution to suggest the factory to pay attention to. SeaRates QA service can assists you to avoid large-scale issues in mass
                    production of your goods, as we present you a report of our effective corrective measures, danger areas in production to watch out
                    for, and suggestions. To keep you informed, we will also report on production efficiency and estimated delivery time of the
                    factory without order follow up service.
                  </p>
                  <p>
                    SeaRates professional inspector will visit the factory on site and does random sampling and relevant checking from the
                    semi-finished or packed products during the mass production.
                  </p>
                  <ul>
                    <li>1. Check safety performance / practicality / appearance</li>
                    <li>2. Upon identifying defects, take corrective action accordingly</li>
                    <li>3. Check details of the product with desired inspection standards</li>
                    <li>4. Focus on key point of the products</li>
                    <li>5. Record findings of the findings and take some samples for further re-checking if necessary</li>
                    <li>6. Inform customer of irreparable defects</li>
                    <li>7. Take photos of all defects</li>
                  </ul>
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>Engineering</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    {" "}
                    There no question that most companies are more comfortable to work with local companies on engineering new product prototypes.
                    This might sound be logically considered that China is on the other side of the world. In addition to the need for design and
                    engineering procurement support from China, other considerations include protecting intellectual property, feasibility studies,
                    development of blueprints, technical drawings, mass production and packaging issues.
                  </p>
                  <p>
                    All above is true, however some companies might consider the cost savings associated with moving engineering and prototyping work
                    to China – SeaRates is an experienced and professional partner in making this happen. We have a great deal of experience
                    developing new products and he have aided dozens of customers develop their products from concept to production to the
                    marketplace. SeaRates has its own in-house design and engineering teams combining excellence with cost savings.
                  </p>
                </div>
              </div>

              <div className="inspection-services-all-details-alignment">
                <div className="inspection-services-all-heading-alignment">
                  <h5>Lab Testing Advise</h5>
                </div>
                <div className="inspection-services-all-child-details-alignment">
                  <p>
                    {" "}
                    SeaRates provides a quick, and cost efficient route for product approvals with its affiliated Testing Laboratory. SeaRates handles
                    all your needs for product certifications; including pre-compliance testing. For organizations with global markets and multiple
                    product categories, SeaRates provides One-Stop-Solution for International Markets.
                  </p>
                  <p>
                    SeaRates is an accredited Certification Body and an accredited Testing Laboratory for various product categories. SeaRates can
                    help get your devices certified for international markets: Canadian, USA, European Union (CE Marking), as well as 43 countries
                    subscribing to the IEC CB Scheme. Our expertise extends from consumer goods, to the healthcare, recreational, marine/offshore and
                    industrial products.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
