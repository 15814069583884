import React from "react";
import "./MarineInsuranceAct1963.scss";
export default function MarineInsuranceAct1963() {
  return (
    <div className="marine-insurance-act1963-section">
      <div className="container">
        <div className="marine-insurance-act1963-alignment">
          <div className="marine-insurance-act1963-heading">
            <h4>Marine Insurance Act 1963</h4>
          </div>

          <div className="marine-insurance-act1963-list">
            <p>
              The Marine Insurance Act of 1963 is a legislative act applied in India that governs marine insurance. It provides regulations and
              guidelines for marine insurance policies, covering the protection of goods and vessels/vehicles through rail road, air and sea.
            </p>
            <p>The act defines the responsibilities of insurers and the process of evaluating losses or damages that occur during marine voyages. Its main objective is to promote fairness and uniformity in the marine insurance sector.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
