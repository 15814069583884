import React from 'react'
import "./TradeServicesTab.scss";
export default function TradeServicesTab() {
    return (
        <div className="trade-services-tab-section">
            <div className="trade-services-tab-alignment">
                <ul>
                    <li>What We supply</li>
                    <li>What we are</li>
                    <li>Why choose us</li>
                    <li>How it works</li>
                    <li>Success story</li>
                    <li>Supply advantages</li>
                    <li>Contact us now</li>
                </ul>
            </div>
        </div>
    )
}
