import React, { useEffect } from "react";
import "./ContainerDimensions.scss";
import DImage1 from "../../assets/Image/di1.png";
import DImage2 from "../../assets/Image/di2.png";
import DImage3 from "../../assets/Image/di3.png";
import DImage4 from "../../assets/Image/di4.png";
import DImage5 from "../../assets/Image/di5.png";
import DImage6 from "../../assets/Image/di6.png";
import DImage7 from "../../assets/Image/di7.png";
import DImage8 from "../../assets/Image/di8.png";
import DImage9 from "../../assets/Image/di9.png";
import DImage10 from "../../assets/Image/di10.png";
import DImage11 from "../../assets/Image/di11.png";
import DImage12 from "../../assets/Image/di12.png";
import DImage13 from "../../assets/Image/di13.png";
import DImage14 from "../../assets/Image/di14.png";
import DImage15 from "../../assets/Image/di15.png";
import DImage16 from "../../assets/Image/di16.png";
import DImage17 from "../../assets/Image/di17.png";
export default function ContainerDimensions() {
  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    })
}, [])
  return (
    <div className="container-dimensions-section">
      <div className="container">
        <div className="container-dimensions-alignment">
          <div className="container-heading-alignment">
            <h4>Shipping container dimensions</h4>
          </div>

          <div className="container-dimensions-body-alignment">
            <div className="container-dimensions-grid">
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>20' STANDARD</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage1} alt="DImage1" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>40' STANDARD</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage2} alt="DImage2" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>40' HIGH-CUBE</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage3} alt="DImage3" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>40' HIGH-CUBE</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage4} alt="DImage4" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>20' OPEN TOP</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage5} alt="DImage5" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>40' OPEN TOP</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage6} alt="DImage6" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>20' FLATRACK</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage7} alt="DImage1" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>40' FLATRACK</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage8} alt="DImage8" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>

              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>20' FLATRACK COLLAPSIBLE</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage9} alt="DImage9" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>40' FLATRACK COLLAPSIBLE</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage10} alt="DImage10" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>20' PLATFORM</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage11} alt="DImage11" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>40' PLATFORM</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage12} alt="DImage12" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>20' REFRIGERATED</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage13} alt="DImage13" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>40' REFRIGERATED</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage14} alt="DImage14" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>20' BULK</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage15} alt="DImage15" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>20' TANK</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage16} alt="DImage16" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="container-dimensions-grid-item">
                <div className="container-dimensions-box">
                  <div className="container-dimensions-box-heading">
                    <h4>45' PALLET WIDE</h4>
                  </div>

                  <div className="container-dimensions-type-img">
                    <img src={DImage17} alt="DImage17" />
                  </div>

                  <div className="container-dimensions-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
