import React from 'react'
import './BuyersSection.scss';
import CardImage from '../../../assets/imges/new-card.png';
export default function BuyersSection() {
  return (
    <div>
        <div className='buyer-section-all-content-alignment'>
            <div className='container4'>
                <div className='all-content-new-page-alignment'>
                    <div className='main-content'>
                        <h1>Hear from our buyers</h1>
                        <div className='grid'>
                            <div className='grid-items'>
                                <div className='card-image'>
                                    <img src={CardImage} alt="CardImage"/>
                                </div>
                                <div className='details'>
                                    <h6>
                                    How Aaziko.com enhanced supplier relations and product discovery
                                    </h6>
                                    <p>Aaziko.com</p>
                                    <div className='last-text'>
                                        <span>May 21, 2021</span>
                                        <span>2 min read</span>
                                    </div>
                                </div>
                            </div>
                            <div className='grid-items'>
                                <div className='card-image'>
                                    <img src={CardImage} alt="CardImage"/>
                                </div>
                                <div className='details'>
                                    <h6>
                                    How Aaziko.com enhanced supplier relations and product discovery
                                    </h6>
                                    <p>Aaziko.com</p>
                                    <div className='last-text'>
                                        <span>May 21, 2021</span>
                                        <span>2 min read</span>
                                    </div>
                                </div>
                            </div>
                            <div className='grid-items'>
                                <div className='card-image'>
                                    <img src={CardImage} alt="CardImage"/>
                                </div>
                                <div className='details'>
                                    <h6>
                                    How Aaziko.com enhanced supplier relations and product discovery
                                    </h6>
                                    <p>Aaziko.com</p>
                                    <div className='last-text'>
                                        <span>May 21, 2021</span>
                                        <span>2 min read</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='btn-center'>
                            <button>Learn More</button>
                        </div>
                    </div>
                    <div className='main-content'>
                        <h1>Lastest from Aaziko.com</h1>
                        <div className='grid'>
                            <div className='grid-items'>
                                <div className='card-image'>
                                    <img src={CardImage} alt="CardImage"/>
                                </div>
                                <div className='details'>
                                    <h6>
                                    How Aaziko.com enhanced supplier relations and product discovery
                                    </h6>
                                    <p>Aaziko.com</p>
                                    <div className='last-text'>
                                        <span>May 21, 2021</span>
                                        <span>2 min read</span>
                                    </div>
                                </div>
                            </div>
                            <div className='grid-items'>
                                <div className='card-image'>
                                    <img src={CardImage} alt="CardImage"/>
                                </div>
                                <div className='details'>
                                    <h6>
                                    How Aaziko.com enhanced supplier relations and product discovery
                                    </h6>
                                    <p>Aaziko.com</p>
                                    <div className='last-text'>
                                        <span>May 21, 2021</span>
                                        <span>2 min read</span>
                                    </div>
                                </div>
                            </div>
                            <div className='grid-items'>
                                <div className='card-image'>
                                    <img src={CardImage} alt="CardImage"/>
                                </div>
                                <div className='details'>
                                    <h6>
                                    How Aaziko.com enhanced supplier relations and product discovery
                                    </h6>
                                    <p>Aaziko.com</p>
                                    <div className='last-text'>
                                        <span>May 21, 2021</span>
                                        <span>2 min read</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='btn-center'>
                            <button>Learn More</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
