import React from 'react'
import './ProductShowcaseBanner.scss';
export default function ProductShowcaseBanner() {
  return (
    <div>
        <div className='product-showcase-banner-all-contnet-alignment'>
            <div className='container'>
                <div className='grid'>
                    <div className='grid-items'>
                        <h1>
                        Position your listings above the competition with Product Showcase
                        </h1>
                        <p>
                            Product Showcase is a tool that allows Gold Suppliers to boost your 
                            product listings so they appear at the top of the marketplace’s search engine result pages.
                        </p>
                    </div>
                    <div className='grid-items'>
                        <div className='box'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
