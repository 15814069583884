import React from "react";
import "./WelcomeSection.scss";
export default function WelcomeSection() {
  return (
    <div>
      <div className="welcome-section-all-contnet-alignment">
        <div className="container">
          <div className="grid">
            <div className="grid-items">
              <h2>Welcome to Aaziko’s World of Compliance!</h2>
            </div>
            <div className="grid-items">
              <p>
                Dive into a universe of creativity, where auditing, and
                compliance are vividly brought to life. Aaziko stands tall as
                the guardians of financial accuracy, ensuring that your
                organization’s processes are efficient, effective, and
                transparent.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
