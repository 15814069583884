import React from "react";
import "./ServicesFees.scss";
export default function ServicesFees() {
  return (
    <div className="services-fees-section">
      <div className="container">
        <div className="services-fees-alignment">
          <div className="services-fees-heading">
            <h4>Freight, shipping and chartering glossary</h4>
          </div>

          <div className="services-fees-body-alignment">
            <div className="services-fees-body-box">
              <div className="services-fees-body-top-alignment">
                <p>A</p>
                <p>B</p>
                <p>C</p>
                <p>D</p>
                <p>E</p>
                <p>F</p>
                <p>G</p>
                <p>H</p>
                <p>I</p>
                <p>J</p>
                <p>K</p>
                <p>L</p>
                <p>M</p>
                <p>N</p>
                <p>O</p>
                <p>P</p>
                <p>Q</p>
                <p>R</p>
                <p>S</p>
                <p>T</p>
                <p>U</p>
                <p>V</p>
                <p>W</p>
                <p>X</p>
                <p>Y</p>
                <p>Z</p>
              </div>

              <div className="services-fees-details-table-alignment table-responsive">
                <table cellPadding={0} cellSpacing={0}>
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        Advance Commercial Information Charge
                        <p>ACI</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Agency Documentation Fee Exports
                        <p>ADE</p>
                      </td>
                      <td className="all-notes">
                        This service covers the creation and processing of documents necessary at Origin to complete a shipment i.e. Bill of Lading
                        (B/L), Delivery Order.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Agency Documentation Fee imports
                        <p>ADI</p>
                      </td>
                      <td className="all-notes">
                        This service covers the creation and processing of documents necessary at Destination to complete a shipment i.e. Bill of
                        Lading (B/L), Delivery Order.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Additional Document Processing
                        <p>ADP</p>
                      </td>
                      <td className="all-notes">Additional Document Processing</td>
                    </tr>
                    <tr>
                      <td>
                        ARRIVAL EMANIFIEST ADMIN FEE
                        <p>AEAF</p>
                      </td>
                      <td className="all-notes"></td>
                    </tr>
                    <tr>
                      <td>
                        AES Filing
                        <p>AES</p>
                      </td>
                      <td className="all-notes">AES Filing</td>
                    </tr>
                    <tr>
                      <td>
                        Administration Fee
                        <p>AF</p>
                      </td>
                      <td className="all-notes">Administration Fee</td>
                    </tr>

                    <tr>
                      <td>
                        AGS
                        <p>AGS</p>
                      </td>
                      <td className="all-notes">AGS</td>
                    </tr>
                    <tr>
                      <td>
                        Airline Handling Fees
                        <p>AHF</p>
                      </td>
                      <td className="all-notes">Airline Handling Fees</td>
                    </tr>
                    <tr>
                      <td>
                        AGTransport Document Amendment Fee - Export
                        <p>AME</p>
                      </td>
                      <td className="all-notes">Transport Document Amendment Fee - Export</td>
                    </tr>
                    <tr>
                      <td>
                        Transport Document Amendment Fee
                        <p>AMF</p>
                      </td>
                      <td className="all-notes">
                        A fee that covers the additional cost when the carrier is requested to make changes to the Transport Document (B/L). These
                        changes could include: - Consignee Details - Address - Cargo Description. Please note that there might be restrictions on how
                        late in the shipment process the carrier is able to arrange for an amendment, e.g. after the containers related to the
                        Transport Documents (TPDoc) are loaded, or if the import procedures have begun. Not all amendment requests may be feasible.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Automated Manifest System
                        <p>AMS</p>
                      </td>
                      <td className="all-notes"></td>
                    </tr>
                    <tr>
                      <td>
                        Algerian Stamp Destination
                        <p>ASD</p>
                      </td>
                      <td className="all-notes">Algerian Stamp Destination</td>
                    </tr>
                    <tr>
                      <td>
                        Algerian Stamp Origin
                        <p>ASO</p>
                      </td>
                      <td className="all-notes">Algerian Stamp Origin</td>
                    </tr>
                    <tr>
                      <td>
                        Air Way Bill Fee
                        <p>AWB</p>
                      </td>
                      <td className="all-notes">Transport document issued by a carrier for air transportation.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
