import React from "react";
import "./OurTraindingSong.scss";
export default function OurTraindingSong() {
  return (
    <div className="our-trainding-song-content">
      <div className="our-trainding-song-heading">
        <h4>Our Trainding Song</h4>
      </div>

      <div className="out-trainding-song-details-alignment">
        <div className="container">
          <div className="out-trainding-song-grid">
            <div className="out-trainding-song-grid-item">
              <iframe
                src="https://www.youtube.com/embed/lwh5jnyE974"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="out-trainding-song-grid-item">
              <iframe
                src="https://www.youtube.com/embed/B3wvZS3qITc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="out-trainding-song-grid-item">
              <iframe
                src="https://www.youtube.com/embed/wzCJdRpr9Ck"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="out-trainding-song-grid-item">
              <iframe
                src="https://www.youtube.com/embed/FBhrW6JleMI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="out-trainding-song-grid-item">
              <iframe
                src="https://www.youtube.com/embed/onokcmD7Grw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="out-trainding-song-grid-item">
              <iframe
                src="https://www.youtube.com/embed/oxxWd8t_oQs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
// Changes in this code scss file