import React , { useEffect } from "react";
import "./PrivacyPolicy.scss";
export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
  return (
    <div>
      <div className="privacy-policy-all-contnet-alignment">
        <div className="container">
          <div className="main-text">
            <h1>Aaziko Privacy Policy</h1>
            <p>
              Aaziko Global LLP ("we," "our," or "us") is committed to
              protecting your privacy. This Privacy Policy explains how your
              personal information is collected, used, and disclosed by Aaziko
              Global LLP. This Privacy Policy applies to our website
              (https://aaziko.com/) and its associated subdomains, as well as
              our application Aaziko.com and its associated subdomains.
            </p>
            <p>
              By accessing or using our services, you signify that you have
              read, understood, and agree to our collection, storage, use, and
              disclosure of your personal information as described in this
              Privacy Policy and our Terms of Service.
            </p>
          </div>
          <div className="same-text">
            <h4>Definitions and Key Terms</h4>
            <p>
              To provide clarity within this Privacy Policy, the following terms
              are strictly defined:
            </p>
            <p>
              <b>Cookie:</b> A small amount of data generated by our website and
              saved by your web browser. It is used to identify your browser,
              provide analytics, and remember information about you, such as
              your language preference or login information.
            </p>
            <p>
              <b>Company:</b> When this policy mentions "Company," "we," "us,"
              or "our," it refers to Aaziko Global LLP, which is responsible for
              your information under this Privacy Policy.
            </p>
            <p>
              <b>Country:</b> In this case, "Country" refers to India, where
              Aaziko Global LLP is based.
            </p>
            <p>
              <b>Customer:</b> Refers to the company, organization, or person
              that signs up to use our Service to manage relationships with
              consumers or service users.
            </p>
            <p>
              Device: Any internet-connected device, such as a phone, tablet,
              computer, or any other device that can be used to visit and use
              our services.
            </p>
            <p>
              IP Address: Every device connected to the Internet is assigned an
              Internet Protocol (IP) address. These numbers are usually assigned
              in geographic blocks and can be used to identify the device's
              location.
            </p>
            <p>
              Personnel: Refers to individuals who are employed by or under
              contract to perform a service on behalf of Aaziko Global LLP.
            </p>
            <p>
              Service: Refers to the service provided by Aaziko Global LLP, both
              through the website (https://aaziko.com/) and the associated
              subdomains, as well as through the application [Insert Application
              Name] and its associated subdomains.
            </p>
            <p>
              Personal Data: Any information that directly, indirectly, or in
              connection with other information, including a personal
              identification number, allows for the identification or
              identifiability of a natural person, as described in the relative
              terms.
            </p>
            <p>
              Third-party service: Refers to advertisers, contest sponsors,
              promotional and marketing partners, and others who provide content
              or whose products or services we believe may interest you.
            </p>
            <p>
              Website: Refers to Aaziko Global LLP's site, which can be accessed
              via the URL:
              <a href="https://aaziko.com/">https://aaziko.com/</a>
            </p>
            <p>
              You: A person or entity that is registered with Aaziko Global
              LLP's site and uses the services provided.
            </p>
          </div>
          <div className="same-text">
            <h4>Information Automatically Collected</h4>
            <p>
              When you visit our platform, certain information such as your
              Internet Protocol (IP) address and/or browser and device
              characteristics is collected automatically. This information is
              used to establish a connection between your device and the
              Internet. Additionally, various other information is collected
              automatically, including login credentials, email addresses,
              passwords, computer and connection details (such as browser
              plug-in types and versions, time zone settings, operating systems,
              and platforms), purchase history, and aggregated data from similar
              information of other users.
            </p>
            <p>
              We may also gather data from your interactions with our website.
              This could encompass the full Uniform Resource Locator (URL)
              clickstream to, through, and from our website, including date and
              time of access, cookie number, parts of the site you viewed or
              searched for, and the phone number used to contact our Customer
              Services. We may employ browser data such as cookies, Flash
              cookies (also known as Flash Local Shared Objects), or comparable
              data on sp
            </p>
            <p>
              During your visits, we might utilize software tools like
              JavaScript to measure and collect session-related data, including
              page response times, download errors, duration of visits to
              specific pages, page interaction details (such as scrolling,
              clicks, and mouse-overs), and methods employed for navigating away
              from a page. Additionally, we may gather technical information to
              assist in identifying your device for fraud prevention and
              diagnostic purposes.
            </p>
            <p>
              As you use or navigate our platform, certain information is
              automatically collected. While this information does not reveal
              your specific identity, it may encompass device and usage data.
              This could include your IP address, browser and device
              characteristics, operating system, language preferences, referring
              URLs, device name, country, location, and information regarding
              when and how you use our services. This data is primarily employed
              to uphold the security and operation of our platform, as well as
              for internal analytics and reporting purposes.
            </p>
          </div>
          <div className="same-text">
            <h4>What Information Do We Collect?</h4>
            <p>
              We collect a range of information from you to enhance your
              experience and provide our services effectively. We gather this
              information through various interactions, including when you visit
              our website, place an order, subscribe to our newsletter, respond
              to a survey, or fill out a form. The specific types of information
              we collect include:
            </p>
            <p>
              Name / Username: Your name or username is collected to personalize
              your experience and facilitate communication.
            </p>
            <p>
              Phone Numbers: We gather phone numbers to ensure effective
              communication and provide important updates related to your
              interactions with Aaziko.
            </p>
            <p>
              Email Address: Your email address is used for communication
              purposes, including sending order confirmations, updates, and
              other essential information
            </p>
            <p>
              Mailing Addresses: Mailing addresses are collected to facilitate
              the delivery of ordered products and provide accurate billing
              information.
            </p>
            <p>
              Debit/Credit Card Numbers: When you make a payment, we collect
              your debit/credit card details to process the transaction
              securely.
            </p>
            <p>
              Age: Age information is collected to ensure compliance with legal
              requirements and provide age-appropriate services.
            </p>
            <p>
              Password: Your password is used to secure your account and protect
              your personal information.
            </p>
            <p>
              By registering on our site, placing an order, subscribing to our
              newsletter, responding to a survey, or filling out a form, you
              voluntarily provide this information to us, allowing us to serve
              you better and improve our services.
            </p>
            <p>
              Additionally, we may collect information from mobile devices to
              enhance the user experience, though these features are completely
              optional:
            </p>
            <p>
              Location (GPS): Location data helps create a more accurate
              understanding of your interests, enabling us to deliver targeted
              and relevant content, including advertisements, to potential
              customers.
            </p>
            <p>
              Phonebook (Contacts list): Access to your contacts list enhances
              user convenience by streamlining interactions. This feature allows
              you to save time when using our app.
            </p>
            <p>
              Camera (Pictures): Granting camera permission allows you to upload
              pictures directly from your device. However, you can choose to
              deny camera permissions for this purpose.
            </p>
            <p>
              Photo Gallery (Pictures): Granting photo gallery access allows you
              to upload pictures from your photo gallery. You can choose to deny
              photo gallery access for this purpose.
            </p>
            <p>
              These features are intended to improve user experience but are
              entirely optional. Your privacy preferences are respected, and you
              can control the permissions you grant to us.
            </p>
          </div>
          <div className="same-text">
            <h4>How Do We Use The Information We Collect?</h4>
            <p>
              We utilize the information collected from you to enhance your
              experience, tailor our services to your needs, and improve our
              offerings. Here's how we use the information:
            </p>
            <p>
              <b>Personalization:</b> The information you provide enables us to
              personalize your experience, ensuring that our services cater to
              your individual preferences and requirements.
            </p>
            <p>
              <b>Improvement of Offerings:</b> We constantly strive to enhance
              our offerings based on the valuable feedback and insights we
              receive from you. Your input guides us in refining and expanding
              our services
            </p>
            <p>
              <b>Customer Service Enhancement:</b> The information you share
              helps us respond more effectively to your customer service
              requests and support needs. By understanding your preferences and
              concerns, we can provide timely and relevant assistance.
            </p>
            <p>
              <b>Transaction Processing:</b> Information such as payment details
              and order information is used to process transactions securely and
              efficiently. Your trust in us is of paramount importance, and we
              ensure that your data is handled with utmost care.
            </p>
            <p>
              <b>Contests, Promotions, and Surveys:</b> If you participate in
              contests, promotions, or surveys, the collected information is
              used to administer these activities, ensure fairness, and deliver
              rewards or benefits.
            </p>
            <p>
              <b>Periodic Emails:</b> We may use the email address you provide
              to send you updates, information about your orders, promotions,
              and other relevant content.
            </p>
            <p>
              You can opt out of receiving these emails at any time by following
              the provided instructions.
            </p>
            <p>
              Your data is utilized responsibly and transparently, ensuring that
              your privacy is respected and your information is safeguarded.
            </p>
          </div>
          <div className="same-text">
            <h4>
              When Does Aaziko Use End User Information from Third Parties?
            </h4>
            <p>
              Aaziko collects End User Data that is necessary to provide the
              services to our customers. This information allows us to
              facilitate and enhance the interactions between end users and the
              Aaziko platform.
            </p>
            <p>
              Additionally, end users may voluntarily choose to provide us with
              information that they have made available on social media
              websites. If you opt to share such information with us, we may
              collect publicly available data from the social media platforms
              you have specified. It's important to note that you have control
              over the extent to which your information is made public on these
              websites. You can manage your privacy settings on these platforms
              to determine what information is visible to others.
            </p>
            <p>
              We prioritize transparency and respect for your preferences when
              it comes to using information from third-party sources. Any
              information collected from third parties is handled in accordance
              with this Privacy Policy and is used exclusively to enhance your
              experience on the Aaziko platform.
            </p>
          </div>
          <div className="same-text">
            <h4>
              When Does Aaziko Use Customer Information from Third Parties?
            </h4>
            <p>
              At times, we may receive certain information from third parties
              when you reach out to us. For instance, when you submit your email
              address to express interest in becoming a customer, we might
              obtain information from a third-party source that specializes in
              automated fraud detection services. This information aids us in
              ensuring a secure and trustworthy environment for all users.
            </p>
            <p>
              Furthermore, we may occasionally gather publicly available
              information from social media websites. If you decide to share
              information with us from these platforms, we may collect data that
              you have made publicly accessible. To control the visibility of
              your information on social media websites, you have the option to
              adjust your privacy settings directly on these platforms.
            </p>
            <p>
              We emphasize transparency and your control over your data. Any
              information collected from third parties is managed in accordance
              with this Privacy Policy and is utilized solely to enhance your
              experience on the Aaziko platform.
            </p>
          </div>
          <div className="same-text">
            <h4>Do We Share the Information We Collect with Third Parties?</h4>
            <p>
              We may share the information, both personal and non-personal, that
              we collect with select third parties who play integral roles in
              enhancing your experience with us. These parties may include
              advertisers, contest sponsors, promotional and marketing partners,
              and others who contribute content or offer products and services
              that we believe might align with your interests. Furthermore, we
              may share this information with our current and future affiliated
              companies, business partners, and in the event of a merger, asset
              sale, or business reorganization, your personal and non-personal
              information may be shared or transferred to our
              successors-in-interest.
            </p>
            <p>
              Trusted third-party service providers may also be engaged by us to
              perform crucial functions and services, such as server hosting,
              database storage and management, email management, marketing,
              credit card processing, customer service, and order fulfillment
              for products and services you may purchase through the Aaziko
              platform. It is likely that we will share your personal
              information, and potentially some non-personal information, with
              these third parties to enable them to provide these services for
              both you and us.
            </p>
            <p>
              For analytical purposes, we may share portions of our log file
              data, including IP addresses, with third parties such as web
              analytics partners, application developers, and ad networks. If
              your IP address is shared, it may be used to estimate general
              location and other technographics, such as connection speed and
              device type. This helps enhance the quality of our services and
              provides insights to optimize user experience.
            </p>
            <p>
              Such third parties may aggregate information about our platform to
              provide auditing, research, and reporting for us and our
              advertisers. Furthermore, we uphold our commitment to transparency
              and may disclose personal and non-personal information about you
              to government or law enforcement officials or private parties as
              we, in our sole discretion, believe it necessary or appropriate.
              This may include situations where claims arise, legal processes
              are involved (including subpoenas), and where the safety of the
              public, individuals, or our interests are at stake. We may also
              disclose information to comply with applicable court orders, laws,
              rules, and regulations.
            </p>
          </div>
          <div className="same-text">
            <h4>
              Where and When Is Information Collected from Customers and End
              Users?
            </h4>
            <p>
              Aaziko collects personal information that you willingly submit to
              us during various interactions. This information is collected at
              different points in time, including when you engage with our
              platform, place an order, participate in surveys, subscribe to our
              newsletter, or complete forms.
            </p>
            <p>
              Furthermore, personal information about you may also be received
              from third parties, as described above in previous sections of
              this Privacy Policy.
            </p>
          </div>
          <div className="same-text">
            <h4>How Do We Use Your Email Address?</h4>
            <p>
              When you provide your email address on this platform, you consent
              to receiving emails from us. Your participation in any of our
              email lists can be canceled at any time by clicking the opt-out
              link or utilizing the unsubscribe option included in the
              respective email. We exclusively send emails to individuals who
              have authorized us to communicate with them directly or through
              third parties. Our commitment to combating unsolicited commercial
              emails aligns with your aversion to spam.
            </p>
            <p>
              By submitting your email address, you also grant us permission to
              utilize it for customer audience targeting on platforms such as
              Facebook. This enables us to display custom advertising to
              specific individuals who have chosen to receive communications
              from us. Email addresses provided exclusively through the order
              processing page will be utilized solely to convey pertinent
              information and updates related to your order. If, however, you
              have provided the same email address through another method, we
              may employ it for any purposes detailed in this Policy.
            </p>
            <p>
              Note: If you ever wish to unsubscribe from receiving future
              emails, we ensure a hassle-free process. Detailed unsubscribe
              instructions are included at the bottom of each email
              communication.
            </p>
          </div>
          <div className="same-text">
            <h4>How Long Do We Keep Your Information?</h4>
            <p>
              We retain your information only for the duration necessary to
              provide you with the services and fulfill the purposes outlined in
              this policy. This principle extends to any parties with whom we
              share your information and who perform services on our behalf. As
              soon as the need for your information to serve its intended
              purposes ceases, and there are no legal or regulatory obligations
              compelling us to retain it, we will take appropriate actions.
            </p>
            <p>
              When we determine that your information is no longer required for
              operational purposes and compliance, we will take one of the
              following actions:
            </p>
            <p>
              <b>Removal:</b> We will expunge your information from our systems,
              ensuring that no trace of your data remains.
            </p>
            <p>
              <b>Depersonalization:</b> Alternatively, we may depersonalize your
              information, rendering it unidentifiable and devoid of personal
              context. This ensures that your identity cannot be inferred from
              the data.
            </p>
            <p>
              Our commitment to responsible data management and your privacy
              guides our decisions regarding the retention of your information.
              Rest assured, we take these measures to safeguard your privacy
              even after your interaction with our platform concludes.
            </p>
          </div>
          <div className="same-text">
            <h4>How Do We Protect Your Information?</h4>
            <p>
              To safeguard your personal information, we implement an array of
              security measures designed to ensure its safety when you place
              orders or interact with your personal information in any way. We
              prioritize the protection of your data.
            </p>
            <p>
              When you provide sensitive or credit-related information, such as
              during the order placement process, we utilize a secure server.
              Your sensitive information undergoes transmission via Secure
              Socket Layer (SSL) technology, ensuring encryption before it is
              stored in our Payment gateway providers' database. Access to this
              data is restricted to individuals who hold specific access
              privileges to these systems and are mandated to maintain its
              confidentiality. Post-transaction, your private information,
              including credit card details, social security numbers,
              financials, and more, is never retained on file.
            </p>
            <p>
              While we enforce rigorous security protocols, it's important to
              acknowledge that no data transmission or storage system can be
              entirely infallible. Consequently, we cannot provide absolute
              assurances of the imperviousness of any information you transmit
              to Aaziko. Although our physical, technical, and managerial
              safeguards are meticulously maintained, we cannot guarantee that
              unauthorized access, disclosure, alteration, or destruction of
              your information won't occur due to potential breaches.
            </p>
          </div>
          <div className="same-text">
            <h4>Could My Information Be Transferred to Other Countries?</h4>
            <p>
              Aaziko is headquartered in , and as such, information gathered
              through our website, acquired via direct interactions with you, or
              obtained from our help services may be occasionally transferred to
              our offices or personnel situated around the world. This also
              extends to certain third parties with whom we collaborate.
              Consequently, your data could be accessed, viewed, and hosted in
              diverse global locations, including countries that may not possess
              comprehensive regulatory frameworks governing the usage and
              transfer of such information.
            </p>
            <p>
              As you explore the functionalities of our platform, please be
              aware that your utilization constitutes voluntary consent to the
              potential trans-border transfer and hosting of your information.
              It's important to note that such transfers are subject to the
              applicable legal provisions governing data protection and privacy.
            </p>
          </div>
          <div className="same-text">
            <h4>Is the Information Collected Through the Service Secure?</h4>
            <p>
              We prioritize the security of your information and take proactive
              measures to ensure its protection. Our comprehensive approach
              encompasses physical, electronic, and managerial procedures aimed
              at averting unauthorized access, maintaining data security, and
              ensuring the accurate utilization of your information. However,
              it's important to recognize that neither individuals nor security
              systems are entirely immune to vulnerabilities, even including
              encryption systems
            </p>
            <p>
              Though we dedicate substantial efforts to shield your personal
              information, we cannot guarantee absolute security due to the
              inherent limitations of security measures. Individuals may engage
              in intentional misconduct, errors, or lapses in adhering to
              established policies. While our efforts to safeguard your personal
              information are considerable, we acknowledge the possibility of
              breaches.
            </p>
            <p>
              In the event that applicable laws impose an obligatory duty to
              ensure the protection of your personal information, you concur
              that the benchmark for measuring our compliance with this duty
              shall be intentional misconduct.
            </p>
            <p>
              Please provide the next part of the privacy policy, and I'll help
              you customize it for Aaziko's services.
            </p>
          </div>
          <div className="same-text">
            <h4>Can I Update or Correct My Information?</h4>
            <p>
              Your ability to request updates or corrections to the information
              collected hinges on your relationship with Aaziko.
            </p>
            <p>
              Personnel: Personnel associated with Aaziko may effect updates or
              corrections to their information as outlined in our internal
              company employment policies.
            </p>
            <p>
              Customers: Customers possess the right to seek limitations on
              certain uses and disclosures of personally identifiable
              information. To effectuate this, you can engage with us in the
              following ways:
            </p>
            <p>
              Update or Correct Information: You may request updates or
              corrections to your personally identifiable information.
            </p>
            <p>
              Preference Modification: Alter your preferences concerning
              communications and other information you receive from us.
            </p>
            <p>
              Delete Information: You can choose to delete the personally
              identifiable information stored about you within our systems by
              cancelling your account. It's important to note that such changes
              won't impact other information maintained or shared in accordance
              with this Privacy Policy before the update, correction, change, or
              deletion.
            </p>
            <p>
              To ensure your privacy and security, we may employ reasonable
              measures (such as requiring a unique password) to verify your
              identity before granting access to your profile or making
              corrections. Maintaining the confidentiality of your unique
              password and account details is your responsibility.
            </p>
            <p>
              Please note that while we strive to accommodate requests, it is
              technologically infeasible to entirely eradicate every record of
              your information from our systems due to necessary system backups.
              Copies of your information may exist in non-erasable forms that
              are challenging or impossible to locate. Following your request,
              personal information stored in actively used databases and other
              searchable media will be updated, corrected, changed, or deleted
              to the best extent practically and technically feasible.
            </p>
            <p>
              For end users who wish to update, delete, or access information
              concerning them, kindly engage directly with the respective
              organization to which you belong as a customer.
            </p>
          </div>
          <div className="same-text">
            <h4>Personnel</h4>
            <p>
              If you are a worker or applicant associated with Aaziko, we
              collect information that you voluntarily provide to us. The
              information collected serves Human Resources purposes,
              facilitating the administration of benefits for workers and the
              screening of applicants.
            </p>
            <p>EVEW</p>
            <p>
              For your convenience, you are welcome to reach out to us for the
              following:
            </p>
            <p>
              Update or Correct Information: You can request updates or
              corrections to your information.
            </p>
            <p>
              Preference Modification: Modify your preferences regarding
              communications and other information you receive from us
            </p>
            <p>
              Request Information Record: Obtain a record of the information we
              possess pertaining to you
            </p>
            <p>
              It's important to note that any changes or deletions you make will
              not affect other information that we maintain, or information
              shared with third parties in alignment with this Privacy Policy
              before the changes or deletions were enacted.
            </p>
          </div>
          <div className="same-text">
            <h4>Sale of Business</h4>
            <p>
              We uphold the right to transfer information to a third party under
              circumstances involving the sale, merger, or other transfer of all
              or significantly all of Aaziko's assets, or assets of any of its
              Corporate Affiliates, or that segment of the Service to which this
              Privacy Policy pertains. This includes instances where we
              discontinue our business operations or become subject to
              bankruptcy, reorganization, or similar proceedings. In such
              events, the third party must commit to honoring the terms stated
              in this Privacy Policy.
            </p>
          </div>
          <div className="same-text">
            <h4>Affiliates</h4>
            <p>
              Information, including personal information, about you may be
              disclosed to our Corporate Affiliates. In the context of this
              Privacy Policy, the term "Corporate Affiliate" refers to any
              individual or entity that possesses direct or indirect control
              over, is controlled by, or shares common control with Aaziko,
              whether through ownership or other means. Any information
              associated with you that we share with our Corporate Affiliates
              will be managed by them in accordance with the provisions laid out
              in this Privacy Policy.
            </p>
          </div>
          <div className="same-text">
            <h4>Governing Law</h4>
            <p>
              This Privacy Policy is subject to the laws of India, without
              consideration of its conflict of laws provisions. You hereby
              consent to the exclusive jurisdiction of the courts in connection
              with any legal action or dispute arising between the parties
              concerning or related to this Privacy Policy, except for
              individuals who may have rights to make claims under Privacy
              Shield or the Swiss-US framework.
            </p>
            <p>
              The laws of India, excluding its conflicts of law rules, shall
              govern this Privacy Policy and your use of the Aaziko platform.
              Your usage of the platform may also be subject to other local,
              state, national, or international laws.
            </p>
            <p>
              By utilizing the Aaziko website or directly getting in touch with
              us, you demonstrate your acceptance of this Privacy Policy. If you
              disagree with this Privacy Policy, you should refrain from
              engaging with our website or utilizing our services. The continued
              use of the website, direct interaction with us, or adherence to
              the updates posted in this Privacy Policy that do not
              significantly alter the utilization or disclosure of your personal
              information will signify your acceptance of those changes.
            </p>
          </div>
          <div className="same-text">
            <h4>Your Consent</h4>
            <p>
              We've taken the steps to update our Privacy Policy to provide you
              with complete transparency about the information collected when
              you visit our site and how it's utilized. By utilizing our
              platform, registering an account, or making a purchase, you
              explicitly consent to our Privacy Policy and agree to its terms.
            </p>
          </div>
          <div className="same-text">
            <h4>Links to Other Websites</h4>
            <p>
              Please note that this Privacy Policy is applicable solely to the
              Services provided by Aaziko. The Services may include links to
              other websites that are not operated or controlled by us. We do
              not assume responsibility for the content, accuracy, or opinions
              expressed on such external websites, and these sites are not
              examined, monitored, or verified for accuracy or completeness by
              us. It's important to be aware that once you navigate from the
              Services to another website through a link, our Privacy Policy is
              no longer effective. Your browsing and interactions on any other
              website, including those linked on our platform, are subject to
              the rules and policies of that respective website. These third
              parties may employ their own cookies or other methods to gather
              information about you.
            </p>
            <p>
              If you have further sections or details to integrate or modify,
              please provide them, and I'll continue to help tailor the privacy
              policy for Aaziko's specific needs.
            </p>
          </div>
          <div className="same-text">
            <h4>Advertising</h4>
            <p>
              This platform may include advertisements from third parties as
              well as links to external third-party websites. It's important to
              note that Aaziko does not provide any assurance regarding the
              accuracy or suitability of the information found in these
              advertisements or on these websites. As such, we do not assume
              responsibility or liability for the conduct or content of these
              advertisements, sites, or the offerings provided by the third
              parties.
            </p>
            <p>
              Advertising plays a vital role in maintaining Aaziko and many
              other websites and services without charge. We put in diligent
              efforts to ensure that ads are secure, minimally intrusive, and as
              pertinent as possible to your needs.
            </p>
            <p>
              Please be aware that third-party advertisements and links to other
              websites where goods or services are promoted should not be
              construed as endorsements or recommendations by Aaziko for the
              third-party sites, goods, or services. We want to clarify that
              Aaziko does not bear responsibility for the content of any
              advertisements, the validity of promises made, or the quality and
              reliability of the products or services featured in these ads.
            </p>
            <p>
              If you have any additional sections or particulars to include, or
              if you would like me to further tailor this information, feel free
              to provide the necessary details.
            </p>
          </div>
          <div className="same-text">
            <h4>Cookies for Advertising</h4>
            <p>
              Aaziko and third parties may use cookies to collect information
              about your online activity and interactions with our platform and
              other online services. These cookies are employed to enhance the
              relevance and effectiveness of online advertisements, tailoring
              them to your interests. This practice is referred to as
              interest-based advertising. Additionally, these cookies help
              manage ad frequency, preventing the same ad from appearing
              repeatedly and ensuring proper ad display for advertisers.
            </p>
            <p>
              Cookies play a crucial role in enabling advertisers to effectively
              reach their target audience and understand metrics such as ad
              impressions and clicks.
            </p>
          </div>
          <div className="same-text">
            <h4>Cookies Usage on Aaziko</h4>
            <p>
              Aaziko employs "Cookies" to identify the specific areas of our
              website that you have visited. A Cookie is a small piece of data
              that is stored on your computer or mobile device through your web
              browser. We use cookies to improve the performance and
              functionality of our platform. While some cookies are essential
              for optimal functionality, others are non-essential but contribute
              to improved user experience.
            </p>
            <p>
              Disabling cookies may lead to certain functionalities, like
              videos, becoming unavailable, or requiring you to log in every
              time you visit our platform. Most web browsers can be configured
              to disable cookies, but doing so might hinder your ability to
              access features on our website accurately or altogether.
              Importantly, we never store Personally Identifiable Information
              within cookies.
            </p>
            <p>
              If you have any specific preferences or requirements regarding
              cookies, or if you'd like further customization, feel free to
              provide more details.
            </p>
          </div>
          <div className="same-text">
            <h4>Blocking and Disabling Cookies and Similar Technologies</h4>
            <p>
              Regardless of your location, you have the option to configure your
              browser to block cookies and similar technologies. However, it's
              important to note that doing so might impair the proper
              functioning of our essential cookies, potentially preventing our
              website from working as intended. This could result in an
              inability to fully utilize all of our platform's features and
              services. Additionally, blocking cookies might lead to the loss of
              certain saved information, such as login details and site
              preferences. Different browsers offer varying levels of control
              for managing cookies. While disabling a cookie or a category of
              cookies won't automatically remove the cookie from your browser,
              you can manually delete cookies from within your browser settings.
              For detailed instructions, consult your browser's help menu.
            </p>
          </div>
          <div className="same-text">
            <h4>Remarketing Services</h4>
            <p>
              We employ remarketing services as part of our digital marketing
              strategy. Remarketing involves the practice of presenting ads
              across the internet to individuals who have previously visited our
              website. This technique enables our company to maintain a presence
              on the internet by displaying ads on the websites and platforms
              that users frequent.
            </p>
          </div>
          <div className="same-text">
            <h4>Payment Details</h4>
            <p>
              Regarding any credit card or payment processing information you
              have shared with us, we want to assure you that we are fully
              committed to storing this confidential data in the most secure
              manner possible. Your payment information's security is of utmost
              importance to us, and we have taken appropriate measures to
              safeguard it.
            </p>
            <p>
              If you have any inquiries or concerns related to blocking cookies,
              remarketing services, or the security of payment details, please
              feel free to provide additional details or questions.
            </p>
          </div>
          <div className="same-text">
            <h4>Kids' Privacy</h4>
            <p>
              Our services are not intended for individuals under the age of 13.
              We do not knowingly collect personally identifiable information
              from anyone under the age of 13. If you are a parent or guardian
              and you are aware that your child under the age of 13 has provided
              us with personal data, please get in touch with us. Should we
              discover that we have inadvertently collected personal data from
              anyone under the age of 13 without appropriate parental consent
              verification, we will take prompt measures to remove that
              information from our servers.
            </p>
          </div>
          <div className="same-text">
            <h4>Changes to Our Privacy Policy</h4>
            <p>
              As our services and policies evolve, adjustments may be necessary
              to our Privacy Policy to accurately reflect these changes. Unless
              legally mandated otherwise, we will notify you (potentially
              through our services) before implementing alterations to this
              Privacy Policy. We'll afford you the opportunity to review these
              changes before they come into effect. If you choose to continue
              using our services, you will be bound by the updated Privacy
              Policy. However, if you do not wish to consent to the revised
              Privacy Policy or any future updates, you have the option to
              delete your account
            </p>
            <p>
              If you have any further inquiries or if there are specific aspects
              of these sections that you'd like to expand upon, feel free to
              provide additional details or ask follow-up questions.
            </p>
          </div>
          <div className="same-text">
            <h4>Third-Party Services</h4>
            <p>
              Our platform may feature, incorporate, or offer access to content,
              applications, products, or services from third parties
              ("Third-Party Services"). We might also provide links that direct
              you to third-party websites or services.
            </p>
            <p>
              You understand and agree that our company is not liable for any
              aspects of Third-Party Services, including their accuracy,
              completeness, timeliness, legality, copyright compliance, decency,
              validity, quality, or any other attributes.
            </p>
            <p>
              We explicitly disclaim any liability or responsibility for you or
              any other individual or entity in connection with any Third-Party
              Services.
            </p>
            <p>
              These Third-Party Services and associated links are provided
              solely for your convenience. You choose to access and utilize them
              entirely at your own risk, and any engagement with these
              third-party offerings is subject to the terms and conditions
              stipulated by those respective third parties.
            </p>
            <p>
              Feel free to let me know if you would like any further elaboration
              or modifications to this section.
            </p>
          </div>
          <div className="same-text">
            <h4>Facebook Pixel</h4>
            <p>
              We utilize Facebook Pixel, an analytics tool that provides
              insights into the efficiency of your advertising efforts by
              tracking user actions on your website. Through the Facebook pixel,
              you can achieve the following objectives:
            </p>
            <p>
              Precision Targeting: Ensure that your advertisements are directed
              towards the appropriate audience. It's important to note that
              Facebook pixel might collect data from your device during your
              interaction with the service. This information collection is
              governed by Facebook's Privacy Policy, which outlines the handling
              and storage of data in compliance with their established
              guidelines.
            </p>
            <p>
              If you require further clarification or adjustments to this
              section, please let me know.
            </p>
          </div>
          <div className="same-text">
            <h4>Tracking Technologies: Google Maps APIs</h4>
            <p>
              The Google Maps API is a versatile tool that offers a range of
              functionalities, including custom map creation, interactive maps,
              location check-ins, real-time data synchronization, route
              planning, and more.
            </p>
            <p>
              When utilizing the Google Maps API, it's important to be aware of
              the data collection that may take place:
            </p>
            <p>
              Data Collection: Google Maps API has the capability to gather
              information from you and your device, primarily for security
              purposes. The information collected through Google Maps API is
              handled in accordance with Google's Privacy Policy, which outlines
              the protocols for data management, storage, and usage.
            </p>
            <p>
              Should you require further elaboration or adjustments to this
              section, feel free to let me know.
            </p>
          </div>
          <div className="same-text">
            <h4>Cookies</h4>
            <p>
              Cookies are small pieces of data stored on your computer or mobile
              device by your web browser. These cookies play a crucial role in
              enhancing the performance and functionality of our services. While
              some cookies are essential for the proper functioning of our
              platform, others are not strictly necessary for basic operations
            </p>
            <p>
              It's important to understand the impact of cookies on your
              experience:
            </p>
            <p>
              Non-Essential Cookies: Some cookies are considered non-essential
              to the core functions of our platform. However, disabling these
              cookies may result in the loss of certain functionalities, such as
              video playback. Additionally, you might be required to enter your
              login credentials each time you visit our platform if you disable
              these cookies.
            </p>
            <p>
              Login Information: Cookies enable us to retain your login
              information, allowing you to access the platform seamlessly
              without the need to log in repeatedly.
            </p>
            <p>
              By utilizing cookies, we can enhance your overall experience on
              our platform. If you have any queries or require adjustments to
              this section, feel free to inform me.
            </p>
          </div>
          <div className="same-text">
            <h4>Local Storage</h4>
            <p>
              Local Storage, also referred to as DOM storage, offers web
              applications a means to store data on the client side. This
              storage method provides persistent data storage similar to
              cookies, but with a larger storage capacity and without the
              inclusion of information in the HTTP request header.
            </p>
            <h4>Sessions</h4>
            <p>
              We utilize "Sessions" to identify the specific sections of our
              website that you have visited. A session involves storing a small
              piece of data on your computer or mobile device through your web
              browser. This allows us to track your interactions and navigate
              your journey on our platform effectively.
            </p>
            <p>
              Please let me know if you require further modifications or
              additions to this section.
            </p>
            <p>Information about General Data Protection Regulation (GDPR)</p>
            <p>
              If you are a resident of the European Economic Area (EEA), we may
              collect and use information from you. In this section of our
              Privacy Policy, we aim to explain in detail how and why we gather
              and protect this data to prevent unauthorized replication or
              misuse.
            </p>
          </div>
          <div className="same-text">
            <h4>What is GDPR?</h4>
            <p>
              The General Data Protection Regulation (GDPR) is a comprehensive
              privacy and data protection law applicable across the European
              Union. It establishes regulations concerning the protection of
              data belonging to EU residents. The GDPR grants EU residents
              greater control over their personal data and mandates that
              companies safeguard this data.
            </p>
            <p>
              The scope of the GDPR extends beyond EU-based businesses and
              residents. It is relevant to any organization that operates on a
              global scale. We consider our customers' data security paramount,
              regardless of their geographical location. As a result, we have
              implemented GDPR controls as the foundational standard for all our
              worldwide operations.
            </p>
            <p>
              Please let me know if you would like any adjustments or additions
              to this information.
            </p>
          </div>
          <div className="same-text">
            <h4>What is Personal Data?</h4>
            <p>
              Personal data refers to any information that pertains to an
              identifiable or identified individual. The General Data Protection
              Regulation (GDPR) encompasses a wide range of information that,
              either on its own or when combined with other data, can be used to
              distinguish a person's identity. Personal data encompasses more
              than just a person's name or email address; it encompasses diverse
              information such as financial details, political beliefs, genetic
              data, biometric data, IP addresses, physical addresses, sexual
              orientation, and ethnicity.
            </p>
            <p>
              The Data Protection Principles within the GDPR stipulate certain
              requirements:
            </p>
            <p>
              Fair, Legal, and Transparent Processing: Personal data must be
              processed in a manner that is equitable, legal, and transparent.
              Any use of personal data should align with reasonable
              expectations.
            </p>
            <p>
              Specific Purpose: Personal data should only be collected for a
              specific purpose, and organizations must clearly outline this
              purpose at the point of data collection.
            </p>
            <p>
              Limited Use: The collected personal data should only be used for
              the specified purpose and not for any other unrelated purpose.
            </p>
            <p>
              Limited Retention: Personal data should not be retained for longer
              than necessary to fulfill its intended purpose.
            </p>
            <p>
              Right to Access: Individuals covered by the GDPR have the right to
              access their own personal data held by organizations.
            </p>
            <p>
              Rights to Rectification and Erasure: Individuals can request
              updates to their personal data or even request its deletion. They
              also have the right to have incorrect information corrected.
            </p>
            <p>
              Data Portability: Individuals can request a copy of their data and
              have the right to request that their data be transferred to
              another organization.
            </p>
            <p>
              It's important to ensure that your data practices align with these
              principles and respect individuals' rights over their personal
              data as outlined in the GDPR. If you require any further
              clarification or elaboration, feel free to let me know.
            </p>
          </div>
          <div className="same-text">
            <h4>Why is GDPR Important?</h4>
            <p>
              The General Data Protection Regulation (GDPR) carries significant
              importance due to several reasons:
            </p>
            <p>
              Enhanced Data Protection: GDPR introduces more stringent
              requirements for companies to safeguard the personal data they
              collect and process. This ensures that individuals have greater
              control over their personal information.
            </p>
            <p>
              Individual Rights: GDPR emphasizes the rights of individuals
              regarding their personal data. It empowers individuals to access,
              correct, and even erase their data held by organizations.
            </p>
            <p>
              Transparency and Accountability: Companies are required to be
              transparent about their data practices and explain why they
              collect and use personal data. They must also be accountable for
              their data processing activities.
            </p>
            <p>
              Global Applicability: Although it's an EU regulation, GDPR applies
              to organizations worldwide that process the data of EU residents.
              This means companies across the globe must adhere to its
              provisions when handling EU residents' data.
            </p>
            <p>
              Consent: GDPR mandates that organizations obtain explicit and
              informed consent from individuals before collecting their data.
              This shifts the focus to a more user-centric approach.
            </p>
            <p>
              Data Breach Notification: GDPR stipulates that organizations must
              notify individuals and authorities about data breaches within a
              specified timeframe. This helps individuals take timely action to
              protect their information.
            </p>
            <p>
              Enforcement and Fines: GDPR significantly increases the potential
              fines for non-compliance. This encourages companies to prioritize
              data protection and motivates them to implement robust security
              measures.
            </p>
            <p>
              Global Data Privacy Standard: GDPR has inspired other countries
              and regions to develop similar data protection regulations. This
              trend is moving toward creating a global standard for data
              privacy.
            </p>
            <p>
              Customer Trust: By complying with GDPR and demonstrating a
              commitment to protecting personal data, companies can enhance
              customer trust and loyalty.
            </p>
            <p>
              Ethical Responsibility: Beyond legal requirements, GDPR promotes
              ethical data handling. Respecting individuals' privacy rights
              aligns with ethical values and fosters a culture of trust.
            </p>
            <p>
              In essence, GDPR seeks to establish a stronger framework for data
              protection, privacy, and accountability in the digital age.
              Adhering to GDPR principles not only ensures legal compliance but
              also contributes to a more secure and respectful data ecosystem.
            </p>
          </div>
          <div className="same-text">
            <h4>
              Individual Data Subject's Rights - Data Access, Portability, and
              Deletion
            </h4>
            <p>
              At Aaziko, we are committed to assisting our customers in
              fulfilling the data subject rights requirements of the General
              Data Protection Regulation (GDPR).
            </p>
            <p>
              As part of our commitment to privacy and data protection, we have
              implemented processes and practices to ensure compliance with
              these regulations.
            </p>
          </div>
          <div className="same-text">
            <h4>Data Storage and Compliance</h4>
            <p>
              Aaziko carefully processes and stores all personal data in
              partnerships with fully vetted and Data Protection Act (DPA)
              compliant vendors. Our priority is to maintain the security and
              integrity of the data entrusted to us.
            </p>
          </div>
          <div className="same-text">
            <h4>Retention Period</h4>
            <p>
              We retain all conversation and personal data for a period of up to
              6 years, unless an account is deleted by the user. In such cases,
              we ensure that all data is disposed of in accordance with our
              Terms of Service and Privacy Policy. Rest assured, we will not
              hold any data longer than 60 days after account deletion.
            </p>
          </div>
          <div className="same-text">
            <h4>Empowering EU Customers</h4>
            <p>
              We understand the importance of providing EU customers with the
              ability to exercise their data rights. If you are working with EU
              customers, it's essential to grant them the capacity to access,
              update, retrieve, and delete their personal data. At Aaziko, we
              have designed our services with self-service capabilities from the
              outset. We have always prioritized giving you, our valued users,
              access to your data as well as your customers' data.
            </p>
          </div>
          <div className="same-text">
            <h4>Support and Guidance</h4>
            <p>
              Our dedicated customer support team is here to assist you at every
              step of the way. Whether you have questions about managing data
              through our API or require guidance on data subject rights, we are
              here to provide the assistance you need. Your data protection
              matters to us, and we are committed to helping you navigate the
              complexities of GDPR compliance.
            </p>
            <p>
              Please feel free to reach out to our customer support team if you
              have any inquiries or require further information about working
              with our platform and APIs in alignment with GDPR regulations.
              Your privacy and data security are paramount to us, and we are
              here to ensure a smooth and compliant experience for all our
              users.
            </p>
          </div>
          <div className="same-text">
            <h4>California Residents</h4>
            <p>
              At Aaziko, we are committed to complying with the California
              Consumer Privacy Act (CCPA) and providing transparency about the
              collection, use, and disclosure of Personal Information. This
              section outlines how we handle your Personal Information in
              accordance with the CCPA.
            </p>
            <p>Categories of Personal Information and How We Use It</p>
            <p>
              As required by the CCPA, we disclose the categories of Personal
              Information we collect and the purposes for which we use them. We
              have provided detailed information about these categories and
              purposes earlier in our Privacy Policy.
            </p>
            <p>Sources of Personal Information and Third-Party Sharing</p>
            <p>
              We also inform you about the categories of sources from which we
              collect Personal Information and the third parties with whom we
              may share it. This information can be found in the relevant
              sections of our Privacy Policy.
            </p>
            <p>California Residents' Rights</p>
            <p>
              Under California law, you have certain rights regarding your
              Personal Information. We are here to help you exercise these
              rights:
            </p>
            <p>
              Right to Equal Service: We will not discriminate against you if
              you choose to exercise your privacy rights.
            </p>
            <p>
              Right to Delete: You have the right to request the deletion of
              Personal Information we have collected about you. If you submit a
              verifiable request to close your account, we will delete the
              relevant Personal Information.
            </p>
            <p>
              Right to Opt-Out of Sale: You may request that we do not sell your
              Personal Information to third parties
            </p>
            <p>
              Right to Know and Access: You can submit a verifiable request to
              obtain information about the categories of Personal Information we
              collect, use, and share; the purposes for which we collect or use
              them; the sources from which we collect Personal Information; and
              specific pieces of Personal Information collected about you.
            </p>
          </div>
          <div className="same-text">
            <h4>Request Response Time</h4>
            <p>
              Upon receiving your request, we will respond within one month as
              required by law. If you would like to exercise any of these rights
              or have further questions, please contact us using the contact
              details provided in our Privacy Policy.
            </p>
          </div>
          <div className="same-text">
            <h4>We Do Not Sell Your Personal Information</h4>
            <p>
              Aaziko does not sell the Personal Information of our users. Your
              privacy is of utmost importance to us, and we take measures to
              ensure the protection and security of your information.
            </p>
            <p>
              For more detailed information about your rights under the CCPA or
              any other inquiries, please do not hesitate to contact us. Your
              privacy matters to us, and we are here to provide the necessary
              information and assistance.
            </p>
          </div>
          <div className="same-text">
            <h4>California Online Privacy Protection Act (CalOPPA)</h4>
            <p>
              We are committed to upholding the requirements of the California
              Online Privacy Protection Act (CalOPPA) and ensuring that your
              privacy is respected. This section outlines how we address your
              rights under CalOPPA and how we handle your Personal Information
              in accordance with this act.
            </p>
          </div>
          <div className="same-text">
            <h4>Categories of Personal Information and How We Use I</h4>
            <p>
              As mandated by CalOPPA, we provide information about the
              categories of Personal Information we collect and the purposes for
              which we use them. Detailed information about these categories and
              purposes can be found in the relevant sections of our Privacy
              Policy.
            </p>
            <p>Sources of Personal Information and Third-Party Sharing</p>
          </div>
          <div className="same-text">
            <h4>CalOPPA Users' Rights</h4>
            <p>
              Under CalOPPA, you have certain rights concerning your Personal
              Information:
            </p>
            <p>
              Right to Know and Access: You can submit a verifiable request to
              obtain information about the categories of Personal Information we
              collect, use, and share; the purposes for which we collect or use
              them; the sources from which we collect Personal Information; and
              specific pieces of Personal Information collected about you.
            </p>
            <p>
              Right to Equal Service: We will not discriminate against you if
              you choose to exercise your privacy rights.
            </p>
            <p>
              Right to Delete: You have the right to request the deletion of
              Personal Information we have collected about you. If you submit a
              verifiable request to close your account, we will delete the
              relevant Personal Information.
            </p>
          </div>
          <div className="same-text">
            <h4>We Do Not Sell Your Personal Information</h4>
            <p>
              Aaziko does not sell the Personal Information of our users. Your
              privacy is a priority, and we take measures to ensure the security
              and protection of your information.
            </p>
            <p>
              For more detailed information about these rights or any other
              inquiries, please feel free to contact us. We are here to address
              your concerns and provide you with the necessary information.
            </p>
            <p>Contact Us</p>
            <p>
              If you have any questions or concerns, please do not hesitate to
              contact us:
            </p>
            <p>Via Email: Contact@aaziko.com</p>
            <p>Via Phone Number: +91 9825135034/+919904224477</p>
            <p>Right to Opt-Out of Sale</p>
            <p>
              You also have the right to request that a business that sells
              consumers' personal data does not sell your personal data. If you
              make a request, we have one month to respond to you. To exercise
              any of these rights, please contact us using the provided contact
              information. Your privacy matters to us, and we are here to assist
              you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
