import React from "react";
import "./StorefrontDetailsSidebar.scss";
export default function StorefrontDetailsSidebar() {
  return (
    <div>
      <div className="store-front-details-sidebar">
        <p>What is it</p>
        <ul>
          <li>Customizable</li>
          <li>Searchable</li>
          <li>Scalable</li>
        </ul>
        <p>Why use it</p>
        <ul>
          <li>Make your brand discoverable</li>
          <li>Reach more buyers</li>
          <li>Mobile-friendly</li>
        </ul>
        <p>How to set up</p>
        <ul>
          <li>Customize your Minisite</li>
          <li>Create a company profile</li>
          <li>List your products</li>
        </ul>
        <p>Optimizing</p>
        <ul>
          <li>Provide plenty of information</li>
          <li>Prioritize your About page</li>
        </ul>
      </div>
    </div>
  );
}
