import React from "react";
import "./OurGlobalMeeting.scss";
import MeetingDemoImg from "../../../../assets/Image/meeting-demo-img.png";
import YouTubeIcon from "../../../../assets/icons/you-tube-icon.svg";
import Image02 from "../../../../assets/Image/02.png";
import Image03 from "../../../../assets/Image/03.png";
import Image04 from "../../../../assets/Image/04.png";
import Image05 from "../../../../assets/Image/05.png";
import Image06 from "../../../../assets/Image/06.png";
import Image07 from "../../../../assets/Image/07.png";
import Image08 from "../../../../assets/Image/08.png";
import Image09 from "../../../../assets/Image/09.png";
import Image010 from "../../../../assets/Image/010.png";
export default function OurGlobalMeeting() {
  return (
    <div className="our-global-meeting-alignment">
      <div className="our-global-heading">
        <h4>Our Global Meeting</h4>
      </div>
      <div className="our-global-meeting-details-alignment">
        <div className="our-global-meeting-detail-grid">
          <div className="our-global-meeting-detail-grid-item">
            <div className="our-global-meeting-detail-box">
              <div className="meeting-img">
                <img src={MeetingDemoImg} alt="MeetingDemoImg" />
              </div>

              <div className="meeting-name-details-content">
                <div className="meeting-name-description">
                  <h6>Turkey</h6>
                  <p>Meeting With Enterpreneurs</p>
                </div>
                <div>
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
              </div>
            </div>
          </div>
          <div className="our-global-meeting-detail-grid-item">
            <div className="our-global-meeting-detail-box">
              <div className="meeting-img">
                <img src={Image02} alt="Image02" />
              </div>

              <div className="meeting-name-details-content">
                <div className="meeting-name-description">
                  <h6>Switzerland</h6>
                  <p>Meeting With Enterpreneurs</p>
                </div>
                <div>
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
              </div>
            </div>
          </div>
          <div className="our-global-meeting-detail-grid-item">
            <div className="our-global-meeting-detail-box">
              <div className="meeting-img">
                <img src={Image03} alt="Image03" />
              </div>

              <div className="meeting-name-details-content">
                <div className="meeting-name-description">
                  <h6>Switzerland</h6>
                  <p>Meeting With Enterpreneurs</p>
                </div>
                <div>
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
              </div>
            </div>
          </div>
          <div className="our-global-meeting-detail-grid-item">
            <div className="our-global-meeting-detail-box">
              <div className="meeting-img">
                <img src={Image04} alt="Image04" />
              </div>

              <div className="meeting-name-details-content">
                <div className="meeting-name-description">
                  <h6>Germany</h6>
                  <p>Meeting With Enterpreneurs</p>
                </div>
                <div>
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
              </div>
            </div>
          </div>
          <div className="our-global-meeting-detail-grid-item">
            <div className="our-global-meeting-detail-box">
              <div className="meeting-img">
                <img src={Image05} alt="Image05" />
              </div>

              <div className="meeting-name-details-content">
                <div className="meeting-name-description">
                  <h6>USA</h6>
                  <p>Meeting With Enterpreneurs</p>
                </div>
                <div>
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
              </div>
            </div>
          </div>
          <div className="our-global-meeting-detail-grid-item">
            <div className="our-global-meeting-detail-box">
              <div className="meeting-img">
                <img src={Image06} alt="Image06" />
              </div>

              <div className="meeting-name-details-content">
                <div className="meeting-name-description">
                  <h6>France</h6>
                  <p>Meeting With Enterpreneurs</p>
                </div>
                <div>
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
              </div>
            </div>
          </div>
          <div className="our-global-meeting-detail-grid-item">
            <div className="our-global-meeting-detail-box">
              <div className="meeting-img">
                <img src={Image07} alt="Image07" />
              </div>

              <div className="meeting-name-details-content">
                <div className="meeting-name-description">
                  <h6>UK</h6>
                  <p>Meeting With Businessman</p>
                </div>
                <div>
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
              </div>
            </div>
          </div>
          <div className="our-global-meeting-detail-grid-item">
            <div className="our-global-meeting-detail-box">
              <div className="meeting-img">
                <img src={Image08} alt="Image08" />
              </div>

              <div className="meeting-name-details-content">
                <div className="meeting-name-description">
                  <h6>Ireland</h6>
                  <p>Meeting With Businessman</p>
                </div>
                <div>
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
              </div>
            </div>
          </div>
          <div className="our-global-meeting-detail-grid-item">
            <div className="our-global-meeting-detail-box">
              <div className="meeting-img">
                <img src={Image09} alt="Image09" />
              </div>

              <div className="meeting-name-details-content">
                <div className="meeting-name-description">
                  <h6>Malaysia</h6>
                  <p>Special Meetup</p>
                </div>
                <div>
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
              </div>
            </div>
          </div>
          <div className="our-global-meeting-detail-grid-item">
            <div className="our-global-meeting-detail-box">
              <div className="meeting-img">
                <img src={Image010} alt="Image010" />
              </div>

              <div className="meeting-name-details-content">
                <div className="meeting-name-description">
                  <h6>Malaysia</h6>
                  <p>Special Meetup</p>
                </div>
                <div>
                  <img src={YouTubeIcon} alt="YouTubeIcon" />
                </div>
              </div>
            </div>
          </div>
          {/* asas */}
        </div>
      </div>
    </div>
  );
}
// Changes in this code scss file