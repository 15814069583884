import React from "react";
import "./AboutusBanner.scss";
export default function AboutusBanner() {
  return (
    <div>
      <div className="aboutus-design-banner">
        <div className="w-full">
          <div className="container">
            <h1>Why sell on Aaziko.com</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas
              integer semper morbi ut auctor in dolor. Porta sed aliquet netus
              ullamcorper nibh quisque lacus. Vel eget massa, nec bibendum
              suspendisse. Porttitor et convallis.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
