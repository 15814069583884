import React from 'react'
import './GrowyourBusiness.scss'
export default function GrowyourBusiness() {
  return (
    <div>
        <div className='grow-your-business-banner'>
            <div className="container">
                <h6>Ready to grow your business</h6>
                <div className='btn-center-alignment'>
                    <button>Chat with Consultant</button>
                </div>
            </div>
        </div>
    </div>
  )
}
