import React, { useEffect } from "react";
import "./UldContainerTypes.scss";
import UDI1 from "../../assets/Image/ucti1.png";
import UDI2 from "../../assets/Image/ucti2.png";
import UDI3 from "../../assets/Image/ucti3.png";
import UDI4 from "../../assets/Image/ucti4.png";
import UDI5 from "../../assets/Image/ucti5.png";
import UDI6 from "../../assets/Image/ucti6.png";
import UDI7 from "../../assets/Image/ucti7.png";
import UDI8 from "../../assets/Image/ucti8.png";
import UDI9 from "../../assets/Image/ucti9.png";
import UDI10 from "../../assets/Image/ucti10.png";
import UDI11 from "../../assets/Image/ucti11.png";
import UDI12 from "../../assets/Image/ucti12.png";
import UDI13 from "../../assets/Image/ucti13.png";
import UDI14 from "../../assets/Image/ucti14.png";
import UDI15 from "../../assets/Image/ucti15.png";
import UDI16 from "../../assets/Image/ucti16.png";
import UDI17 from "../../assets/Image/ucti17.png";
import UDI18 from "../../assets/Image/ucti18.png";
import UDI19 from "../../assets/Image/ucti19.png";
import UDI20 from "../../assets/Image/ucti20.png";
import UDI21 from "../../assets/Image/ucti21.png";
import UDI22 from "../../assets/Image/ucti22.png";
import UDI23 from "../../assets/Image/ucti23.png";
import UDI24 from "../../assets/Image/ucti24.png";
import UDI25 from "../../assets/Image/ucti25.png";
import UDI26 from "../../assets/Image/ucti26.png";
import UDI27 from "../../assets/Image/ucti27.png";
import UDI28 from "../../assets/Image/ucti28.png";
import UDI29 from "../../assets/Image/ucti29.png";
import UDI30 from "../../assets/Image/ucti30.png";
export default function UldContainerTypes() {
  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    })
}, [])
  return (
    <div className="uld-container-types-section">
      <div className="container">
        <div className="uld-container-types-alignment">
          <div className="container-heading-alignment">
            <h4>Uld Container Types</h4>
          </div>

          <div className="uld-container-types-body-alignment">
            <div className="uld-container-types-grid">
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>Demi</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI1} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>

              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>HMA stall</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI2} alt="UDI2" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-1</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI3} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-11</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI4} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-2</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI5} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-9 Reefer</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI6} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-4</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI7} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-7 with Folding Wings</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI8} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-7 with Angled Wings</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI9} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-39</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI10} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>M-1H</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI11} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-29 Reefer</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI12} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-3 Reefer</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI13} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-26</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI14} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-29</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI15} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-3</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI16} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>M-2</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI17} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>MDP</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI18} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>M-1</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI19} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>M-6 (118"H)</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI20} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-8</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI21} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-7</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI22} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>M-6</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI23} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>Type A Pen</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI24} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>PNA Half Pallet</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI25} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>PLA Half Pallet</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI26} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>PMC/P6P Pallet</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI27} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>M-6 Twin Car Rack</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI28} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-9</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI29} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>

              <div className="uld-container-types-grid-item">
                <div className="uld-container-types-box">
                  <div className="uld-container-types-box-heading">
                    <h4>LD-6</h4>
                  </div>

                  <div className="uld-container-types-type-img">
                    <img src={UDI30} alt="UDI1" />
                  </div>

                  <div className="uld-container-types-learn-more-alignment">
                    <a>LEARN MORE</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
