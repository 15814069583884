import React from 'react'
import './Submenu.scss';
export default function Submenu() {
  return (
    <div>
        <div className='sub-menu'>
            <span>Storefront and catalog</span>
            <span>Communication</span>
            <span>Ads and lead generation</span>
            <span>Analytics and support</span>
            <span>Order management</span>
        </div>
    </div>
  )
}
